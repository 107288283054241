
export default {
  data: () => ({
    banner: null,
    loading: false
  }),
  fetchOnServer: true,
  async fetch () {
    const resp = await this.$backend.diagrams.get({ ordering: '-refresh_date', limit: 1, for_sale: 'true' })
    this.banner = resp.results[0]
  },
  methods: {
    seeMore () {
      this.loading = true
      this.$router.push(`/diagrams/${this.banner.url}`)
    }
  }
}
